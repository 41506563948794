import { Component, EventEmitter, Output } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { takeUntil } from 'rxjs';
import { ResourceService } from '../../../services/resource.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';

@Component({
  selector: 'app-resource-dialog-filter',
  templateUrl: './filter-resource-sidebar.component.html',
})
export class FilterResourceSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  accountID: string = '';
  items = ['Roles'];
  expandedIndex = 0;
  loading: boolean = false;
  resource: any[] = [];
  roles: any[] = [];
  selectedRoles: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'name' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';

  selectedRolesMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private resourceService: ResourceService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.getResourceList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  getResourceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.resource = data?.data?.records;
          this.extractRoles();
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
        },
      });
  }

  extractRoles(): void {
    const roles = this.resource.map((res) => res.re_role);
    this.roles = Array.from(new Set(roles));
  }

  hasSelectedFilters(): boolean {
    return this.selectedRoles.length > 0 || this.selectedRoles.length > 0;
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedRoles: this.selectedRoles,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedRoles = [];
    this.selectedRolesMap = {};
    this.applyFilters();
    this.filtersApplied.emit({});
  }

  onRoleChange(checked: boolean, role: string): void {
    if (checked && !this.selectedRoles.includes(role)) {
      this.selectedRoles.push(role);
    } else if (!checked && this.selectedRoles.includes(role)) {
      const index = this.selectedRoles.indexOf(role);
      if (index > -1) {
        this.selectedRoles.splice(index, 1);
      }
    }
  }

  toggleRole(event: any, role: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}
