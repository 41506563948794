import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { ResourceService } from '../../services/resource.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ResourceDialogComponent } from 'src/shared/components/dialog/admin-dialogs/resource-dialog/resource-dialog';
import { FilterResourceSidebarComponent } from './filter-sidebar/filter-resource-sidebar.component';

export interface PeriodicElement {
  resourceName: string;
  role: string;
  pricing: string;
  lastupdated: string;
}

@Component({
  selector: 'app-resource-module',
  templateUrl: './resource-listing.component.html',
})
export class ResourceListingComponent implements OnInit {
  resource: any[] = [];
  roles: any[] = [];
  loading: boolean = false;
  isSidebarOpen = false;
  isSidepanelOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'name' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  form!: FormGroup;
  element: any;
  costModal: string | undefined = undefined;
  lastSortBy: string = '';
  totalRecords: number = 0;
  canManageResources: boolean = false;
  isFilterApplied = false;

  displayedColumns: string[] = [
    'resourceName',
    'role',
    'pricing',
    'lastupdated',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  constructor(
    public dialog: MatDialog,
    private resourceService: ResourceService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('filter') filterComponent!: FilterResourceSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const resourcePermissions = userPermissions.find(
        (p: any) => p.module === 'resource'
      );
      if (
        resourcePermissions &&
        resourcePermissions.permission.includes('view') &&
        resourcePermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.sortBy = 'name';
    this.orderBy = 'DESC';
    this.getResourceList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );

    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchResource();
  }

  getResourceListByRole(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedRoles: string[]
  ): void {
    this.loading = true;
    const role = selectedRoles.join('&role=');
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy, role)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.resource = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in  Resource List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    const selectedRoles = filters.selectedRoles || [];
    if (selectedRoles.length > 0) {
      this.getResourceListByRole(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedRoles
      );
    } else {
      this.getResourceList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.resourceService
      .getResourceList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy
      )
      .subscribe({
        next: (data: any) => {
          this.resource = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.resource;
          this.loading = false;
          this.extractRoles();
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getResourceList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  getResourceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    role?: string
  ): void {
    this.loading = true;
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy, role)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.resource = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.extractRoles();
          this.resource.sort((a, b) => {
            return (
              new Date(b.re_modified_at).getTime() -
              new Date(a.re_modified_at).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
          this.loading = false;
        },
      });
  }

  extractRoles(): void {
    const roles = this.resource.map((res) => res.role);
    this.roles = Array.from(new Set(roles));
  }

  searchResource(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getResourceList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  openAddDialog(): void {
    if (this.canManageResources) {
      const dialogRef = this.dialog.open(ResourceDialogComponent, {
        height: 'auto',
        width: '467px',
        data: { isEditMode: false },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.success) {
          this.getResourceList(
            this.pageNumber,
            this.limit,
            this.search,
            this.sortBy,
            this.orderBy
          );
        }
      });
    }
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
}
