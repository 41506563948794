<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Resource
        </h4>
        <p class="medium mat-body-1 text textXS" style="margin-top: 0">
          Manage Your Resources
        </p>
      </div>
      <div class="d-flex align-center">
        <mat-form-field
          [formGroup]="form"
          appearance="outline"
          class="search-input"
        >
          <input
            formControlName="searchQuery"
            type="text"
            matInput
            placeholder="Search"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="d-flex mr-2 justify-between header-info-wrapper">
          <button
            mat-button
            class="outline-button"
            (click)="openFilterDrawer()"
          >
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
            <span class="mr-2">Filter</span>
            <div *ngIf="isFilterApplied" class="active-circle"></div>
          </div>
          </button>
        </div>
        <sft-button
          [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'"
          [label]="'Add Resource'"
          [color]="'primary'"
          [variant]="'flat'"
          [icon]="'home'"
          [size]="'xl'"
          [disable]="!canManageResources"
          [matTooltip]="
            canManageResources
              ? ''
              : 'You do not have permission to add resources'
          "
          (click)="openAddDialog()"
        ></sft-button>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div
      *ngIf="!loading && resource.length === 0"
      class="required-star"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No resources found!
    </div>
    <div
      class="body-bg listing-content overflow-auto resource-listing resource-info-wrapper"
      *ngIf="!loading && resource.length > 0"
    >
      <table
        mat-table
        [dataSource]="resource"
        matSort
        class="custom-action-table"
        style="cursor: pointer"
        (matSortChange)="onSort($event)"
      >
        <ng-container matColumnDef="resourceName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="name-info"
            mat-sort-header="name"
          >
            Resource Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/resource/view/' + element.re_resourceID"
            title="{{ element.re_name || '--' }}"
          >
            <div class="d-flex align-center">
              <div class="table-avatar mr-2" style="position: relative">
                <ng-container *ngIf="element.logo; else defaultLogo">
                  <img
                    src="/images/profile.png"
                    alt="profile"
                    style="border-radius: 50%"
                  />
                </ng-container>
                <ng-template #defaultLogo>
                  <div
                    class="table-avatar mr-2"
                    style="
                      background-color: #005F8E;
                      color: #fff;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ getDefaultPhotoText(element.re_name) }}
                  </div>
                </ng-template>
              </div>
              <span class="grey-text name-info-col table-ellipse" title="{{
                element.re_name || '--'
              }}">{{
                element.re_name || "--"
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef class="role" mat-sort-header>
            Role
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text name-info-col table-ellipse"
            [routerLink]="'/resource/view/' + element.re_resourceID"
            title=" {{ element.re_role || '--' }}"
          >
            {{ element.re_role || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="pricing">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="salary"
            class="role"
          >
            Resource Salary
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text"
            [routerLink]="'/resource/view/' + element.re_resourceID"
          >
            {{
              element.re_vsalary !== null && isValidNumber(element.re_salary)
                ? (element.re_salary | number : "1.2-2")
                : "--"
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastupdated">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="role"
            mat-sort-header="modifiedAt"
            sortActionDescription="Sort by date"
          >
            Last Updated
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text"
            [routerLink]="'/resource/view/' + element.re_resourceID"
          >
            {{
              element.re_modified_at
                ? (element.re_modified_at | date : "d MMM y h:mm a" : "UTC")
                : "--"
            }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        class="action-table-pagination fixed bottom-0 resource-listing"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-resource-dialog-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-resource-dialog-filter>
