import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../../shared/modules/angular-material/angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SftButtonModule } from '../../../../stories/components/button/button.module';
import { SftInputModule } from '../../../../stories/components/Input/input.module';
import { SftSwitchButtonModule } from '../../../../stories/components/switch/switch.module';
import { StepperComponent } from '../../../../stories/components/stepper/stepper.component';
import { SftRadioButtonModule } from 'src/stories/components/radiobutton/radio.module';
import { StorybookGlobalModule } from 'src/stories/components/storybook-global.module';
import { ActionTableComponent } from 'src/stories/components/action-table/action-table.component';
import { ResourceListingComponent } from './components/resource-listing/resource-listing.component';
import { RouterModule } from '@angular/router';
import { ResourceDetailComponent } from './components/resource-detail/resource-detail.component';
import { ResourceRoutingModule } from './resource-routing.module';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { FilterResourceSidebarComponent } from './components/resource-listing/filter-sidebar/filter-resource-sidebar.component';

@NgModule({
  declarations: [
    ResourceListingComponent,
    ResourceDetailComponent,
    FilterResourceSidebarComponent,
  ],
  imports: [
    LoaderComponent,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    StepperComponent,
    SftRadioButtonModule,
    StorybookGlobalModule,
    ActionTableComponent,
    RouterModule,
    ResourceRoutingModule,
    SharedModule,
  ],
})
export class ResourceModule {}
